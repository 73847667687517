/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


html{
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  height: 100%;
  min-height: 100vh;
}

/* add hidden to opbtionback! */

@tailwind base;
@tailwind components;
@tailwind utilities;


.btn{
 @apply flex justify-center items-center cursor-pointer bg-white 
}

.ingredient-page-section{
  @apply h-full w-full flex
}

.section-title{
  @apply pt-9 h-1/5 flex text-white
}

.section-ingredients-list-container{
  @apply flex flex-col w-full h-full items-center justify-start
}

.section-chosen-ingredients-list-item{
  @apply mb-2 
  flex justify-between items-center
  border border-white rounded-full
  px-3 py-1
}

.section-arrow{
  @apply w-full h-2/5 flex justify-end items-end pb-32 pr-6 text-white
}

.delete-icon{
 @apply flex justify-center items-center bg-white rounded-full w-4 h-4
}

.ingredientGrid{
  @apply p-3 grid gap-1 text-center text-xs
  grid-cols-3 grid-rows-1
  sm:p-0
  xs:text-base
  sm:text-base
  md:text-sm
  lg:text-base
  xl:text-lg
  2xl:text-xl
}

.ingredientGridItem{
  @apply bg-blue-50 rounded p-5 cursor-pointer
  flex justify-center items-center 
  transform sm:hover:scale-95
  sm:p-1
  h-20
  xs:h-24
  sm:h-28
  md:h-24
  lg:h-28
  2xl:h-32
}

.optionsContainer{
  @apply fixed top-0 left-full z-0 overflow-y-scroll overflow-x-hidden
  ease-in duration-500 transform translate-x-0 h-full w-full
  bg-white
}

.ingredient-header{
  @apply w-full flex justify-center text-2xl p-3 text-white mb-3
}

.ingredients-container{
  @apply flex
}

.chosen-ingredient-container{
  @apply flex
}

.ingredient-remove-button{
  @apply flex
}

.quickSelect-container{
  @apply pb-1 mx-5 mb-2 py-1  border-b w-max
}

.quickSelect-text{
  @apply text-lg font-bold
}

.alcoholAltColors{
  @apply bg-red-400
}

.mixerAltColors{
  @apply bg-green-400
}

.extraAltColors{
  @apply bg-blue-400
}

.slideInLeft{
  @apply flex transition ease-in duration-500 transform -translate-x-full
}

.hide{
  @apply hidden
}

.noSelect{
  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}





